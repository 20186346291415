body {
	font-size: 12px;
	/* min-width: 1400px; */
}

.btn {
	font-size: 12px;
}
.small, small {
	font-weight: inherit;
}
.sk-spinner-wave div {
	margin: 0 1px;
} 
.nav > li > a.active {
	color: #ffffff;
}
tr.active {
	background-color: lightSteelBlue !important;
}
.breadcrumb-item.active {
	font-weight: bold;
}
.feature-image {
	float: left;
	width: 300px;
	margin-right: 20px;
	width: 100%;
	border: 1px solid #e5e6e7;
	padding: 8px;
}
.nowrap {
	white-space: nowrap;
}
.absolute-lower-right {
	position: absolute;
	right: 20px;
	bottom: 20px;
}
.ibox-content.no-fade.sk-loading:after {
	background-color: rgba(255, 255, 255, 0);
}
.dropdown-item {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
	background-color: #eeeeee !important;
}
.dropdown-item.active {
	background-color: lightSteelBlue !important;
}

.react-datepicker-popper {
	z-index: 2 !important;
}
.ps-menu-button:hover > .ps-active.ps-menu-icon,
.ps-menu-button:hover > .ps-active.ps-menu-label {
	background-color: #bfa34d !important;
}
#wrapper {
    background-color: #f3f3f4;
}
#page-wrapper {
	position: inherit;
	min-height: calc(100vh - 100px);
}
.analytics-title {
	text-align: center;
	background-color: palegoldenrod;
	border-radius: 15px;
	padding: 5px;
}
.form-group.text-right input {
	text-align: right;
}
.profile-image {
	position: relative;
	width: 120px;
	float: left;
}
.profile-image-right {
	width: 120px;
	float: right;
}

.tooltip {
	z-index: 3000 !important;
}
.navbar-toggler:hover {
	color: inherit;
}
.icheckbox_square-green {
	cursor: default;
}

/* Pop-Over ----------------------------------------*/

#popover-basic {
	/* margin-right: 15px !important;
	margin-left: 15px !important; */
}
#popover-basic, #popover-basic > div {
	border-radius: 10px;
	background-color: transparent;
}
.bs-popover-top>.arrow::after {
    border-top-color: rgba(0, 0, 0, 0.8);
}
.popover {
    max-width: 376px;
	color: white;
}
.popover li {
	font-size: 16px;
	font-weight: 100;
}
.popover ul {
	padding-left: 25px;
}
.popover h1 {
	margin-top: 0;
	font-weight: 300;
}
.popover strong {
	font-weight: 400;
	text-decoration: underline;
	text-decoration-color: #aaaaaa;
}

/* line Clamping ----------------------------------------*/

.line-clamp-1 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical; 
}
.line-clamp-2 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
}

/* React Mulit Date Picker ----------------------------------------*/

.rmdp-container {
	width: 100%
}
.rmdp-calendar-container-mobile {
    z-index: 9001 !important;
}

/* WRAPPING BUTTON GROUPS -------------------------------*/

/* .btn-group {
	flex-wrap:wrap;
} */

/* MOBILE SCROLLBARS ------------------------------------*/

/* ::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
} */

/* WIDGET -----------------------------------------------*/

.widget.style1 h2 {
    font-size: 26px;
}

/* iBOX TOOLS -------------------------------------------*/

.ibox-title {
	padding-right: 15px;
}
.ibox-title h5 {
	display: block
}
.ibox-tools {
    display: inline;
    float: none;
	position: relative;
	top: 0;
	right: 0;
    padding: 0;
}

/* LINE THROUGH -----------------------------------------*/

.line-through {
    position: relative;
    font-size: 14px;
    z-index: 1;
    overflow: hidden;
    text-align: center;
}
.line-through:before, .line-through:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: #cccccc;
}
.line-through:before {
    margin-left: -50%;
    text-align: right;
}

/* CAROUSEL -----------------------------------------*/

.carousel-control-next, .carousel-control-prev {
	border: none;
    background-color: transparent;
	opacity: 1;
	font-size: 18px;
}
.carousel-indicators li {
    background-color: #aaa;
}
.carousel-control-prev, .carousel-control-next {
    color: #aaaaaa;
}
 .carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #666666;
}
.carousel-caption {
    color: #666666;
    text-align: center;
	position: relative;
	left: 0;
	right: 0;
	margin-top: -60px;
	padding-bottom: 35px;
}
/* OFF CANVAS DOCUMENTATION PANEL -----------------------------------------*/

.bs-canvas {
	top: 0;
	z-index: 2200;
	overflow-x: hidden;
	overflow-y: auto;
	width: 530px;
	transition: margin .3s ease-out;
	-webkit-transition: margin .3s ease-out;
	-moz-transition: margin .3s ease-out;
	-ms-transition: margin .3s ease-out;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.bs-canvas-right {
	right: 0;
	margin-right: -560px;
}

/* DASHBOARD DETAILS -----------------------------------------*/

.details-row {
	display: flex; 
	margin-bottom: 3px;
	margin-left: 3px;
}
.details-field {
	width: 110px;
	text-align: right;
	background-color: #E8E8E8;
	padding: 2px 5px;
}
.details-icon {
	width: 20px;
	text-align: center;
	background-color: #FFFFFF;
	padding: 2px 2px;
}
.details-gap {
	width: 8px;
}
.details-value {
	flex: 1;
	text-align: left;
	background-color: #F8F8F8;
	padding: 2px 7px;
}
.details-row:hover .details-value {
	background-color: #eeeeee;
}

/* FOOTER -----------------------------------------*/

.footer {
	position: fixed;
	z-index: 1000;
	margin-left: 80px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

/* NESTED ACCORDIAN -------------------------------*/

.card.card-table > .card-header {
	padding: 10px 1.25rem 5px;
	border-radius: none;
}

/* STATUS MODAL -----------------------------------*/

.tabs-container .tabs-left .panel-body {
	width: 77%;
	margin-left: 23%;
}
.tabs-container .tabs-left > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs {
	width: 23%;
}
.tabs-container .tabs-left .tab-pane .panel-body {
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}
.tabs-container .tabs-left > .nav-tabs {
	float: left;
	margin-right: 30px;
}

/* STATUS DROPDOWNS -----------------------------------*/

.dropdown-menu.show {
	display: block;
	max-height: 400px;
	overflow: hidden;
	overflow-y: auto;
}

/* EDITABLE LINE ITEMS --------------------------------*/

tr.stripe-every-two:nth-of-type(4n + 1),
tr.stripe-every-two:nth-of-type(4n + 2) {
	background-color: rgba(0, 0, 0, 0.04);
}

/* SMOOTH DND LOOK LIKE TABLE -------------------------*/

.smooth-dnd-container .smooth-dnd-draggable-wrapper:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

/* MAIN HEADINGS---------------------------------------*/

.heading-service-request {
	background-color: olive;
	color: white;
	padding: 6px 15px;
	border-radius: 5px;
	width: 100%;
}
.heading-work-order {
	background-color: burlywood;
	color: black;
	padding: 6px 15px;
	border-radius: 5px;
	width: 100%;
}
.heading-quote {
	background-color: steelblue;
	color: white;
	padding: 6px 15px;
	border-radius: 5px;
}

/* SCROLLBARS -----------------------------------------*/

@media screen and (min-width: 980) {
	::-webkit-scrollbar {
		background-color: #dddddd;
		width: 12px;
	}
	::-webkit-scrollbar-button {
		background-color: #aaaaaa;
		height: 15px;
	}
	::-webkit-scrollbar-track {
		background-color: #dddddd;
	}
	::-webkit-scrollbar-thumb {
		background-color: #bbbbbb;
		border-radius: 12px;
		margin: 3px 0;
	}
}

/* MAIN UI -------------------------------------------------*/

.nav-tabs .nav-link {
	background-color: #eeeeee; /* Give some color to non-selected tabs */
	border-color: #dee2e6 #dee2e6 #fff;
}
.tabs-container .panel-body,
.panel-body {
	padding: 0; /* Tab Content already has padding */
}
.ibox-title {
	border: none;
}
.ibox-content {
	position: relative; /* Relative for absolute positioning within content */
}
.no-caret::after {
	display: none;
}
.dropdown-toggle.no-caret::after {
	display: none;
}
.dropdown-toggle.no-caret::before, div.no-caret > button::before {
	display: none !important;
}
div.no-caret > button {
	padding-left: 3px;
	padding-right: 3px;
	background-color: inherit;
	border: none;
}
.react-datepicker__close-icon::after {
	background-color: #aaaaaa !important;
}

.middleOfLine {
	display: flex;
	flex-direction: row;
}
.middleOfLine:before,
.middleOfLine:after {
	content: "";
	flex: 1 1;
	border-bottom: 3px solid lightgrey;
	margin: auto;
}     

/* COLUMNS -------------------------------------------------*/

#column1,
#column2,
#column3,
#column4,
#column5 {
	background-color: #f3f3f4;
}
#column2 {
	position: absolute;
	z-index: 1000;
}
#column3 {
	position: absolute;
	z-index: 1001;
}
#column4 {
	position: absolute;
	z-index: 1002;
}
.animate-left {
	transition: left 0.2s ease;
}
.animate-opacity {
	transition: opactiy 0.2s ease;
}

/*--------------------------------------------------------------------------------
/* SCHEDULE
/*--------------------------------------------------------------------------------

/* Profile -----------------------------------*/

.ibox-title.profile {
	height: 45px;
	min-height: 0px;
	background-color: white;
}
.agile-list li.default-element.profile {
	height: 75px;
	border: none;
	text-align: center;
	margin: 0 auto 0 auto;
}

/* Daily -----------------------------------*/

.progress-bar {
	transition: width 0s ease;
}
.agile-list li.primary-element {
	border-left: 3px solid #1c84c6;
}
.agile-list li.info-element {
	border-left: 3px solid #23c6c8;
}
.sortable-list {
	padding-top: 0;
}
.sortable-list.schedule.hourly {
	display: flex;
	flex-direction: row;
	width: 2420px;
}
.sortable-list.schedule.hourly.timeslot {
	background-color: #fafafb;
	padding: 0;
	margin-bottom: 20px;
	width: 2400px;
}
.sortable-list.unassigned {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.agile-list li.hourly {
	width: 190px;
	height: 75px;
	margin: 0 10px 0 0;
}
.agile-list li.default-element {
	width: 90px;
	background-color: white;
	border: none;
	border-left: 1px dashed #aaaaaa;
}
.agile-list li.heading {
	width: 100%;
	height: 20px;
	padding: 0;
	text-align: center;
}
.agile-list li.timeslot {
	width: 90px;
	height: 20px;
	margin-right: 10px;
	margin-bottom: 0;
	font-size: 12px;
	padding: 0 10px;
	border-radius: 0;
	border: none;
	border-left: 1px dashed #aaaaaa;
	background-color: transparent;
	white-space: nowrap;
	overflow-x: hidden;
}
.agile-list li.timeslot:nth-child(odd) {
	border-left: 2px solid #aaaaaa;
}
.agile-list li.timeslot:after {
	color: #aaaaaa;
	content: "----------------------------------------------------------------------------------------";
	overflow-x: hidden;
}

/* Weekly -----------------------------------*/
.ibox-title.schedule {
	background-color: #dddddd;
}
.ibox-content.schedule,
.schedule .agile-list li.default-element {
	background-color: #f3f3f4;
}

.sortable-list.schedule.daily {
	display: flex;
	flex-direction: column;
}
.sortable-list.unassigned {
	display: flex;
	flex-direction: row;
}
.sortable-list.unassigned li.daily {
	margin-right: 10px;
	white-space: normal;
	max-width: 300px;
	overflow: auto;
	min-height: 40px;
	height: unset;
}
.agile-list li.daily,
.agile-list li.daily div {
	height: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
#schedule-popover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* LINE ITEMS ----------------------------------------------*/

.cart-product-imitation {
	text-align: center;
	padding-top: 24px;
	height: 80px;
	width: 80px;
	background-color: #eeeeee;
}
.cart-product-imitation i {
	font-size: 30px;
	color: #aaaaaa;
}
.cart-product-imitation img {
	margin-top: -24px;
	width: 100%;
}

/* SIDEBAR -------------------------------------------------*/

.pro-sidebar {
	z-index: 1000 !important; /* SIDEBAR Z-INDEX BELOW LIGHTBOX ---------------------*/
}
.pro-sidebar-footer {
	position: fixed;
	bottom: 3px;
	width: 220px;
	text-align: center;
}
nav.ps-menu-root .dropdown-menu {
	padding: 8px 0;
}
.ps-sidebar-container {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* MOBILE OPTIMIZATION -------------------------------------*/

@media only screen and (max-width: 980px) {
	#page-wrapper {
		padding: 0;
	}
	.row.wrapper {
		margin: 0;
	}
	.tabs-container .panel-body {
		padding: 10px;
	}
	.ibox-content {
		padding-left: 0px;
		padding-right: 0px;
	}
	.modal-body {
		padding-left: 0px;
		padding-right: 0px;
	}
}
.mini-navbar .navbar-static-side .nav .nav-second-level {
	padding: 10px 10px 10px 10px;
}
.mini-navbar .navbar-static-side .nav .active {
	background-color: #333333;
}
.mini-navbar .navbar-static-side .nav .nav-third-level {
	padding-left: 10px;
}
.mini-navbar .navbar-static-side .nav .nav-fourth-level {
	padding-left: 10px;
}

/* TABLES --------------------------------------------------*/

tr.stripe_color_1,
div.stripe_color_1 {
	border-left: 3px solid #a68d42 !important;
}
tr.stripe_color_2,
div.stripe_color_2 {
	border-left: 3px solid #32cd32 !important;
}
tr.stripe_color_3,
div.stripe_color_3 {
	border-left: 3px solid #23c6c8 !important;
}
tr.stripe_color_4,
div.stripe_color_4 {
	border-left: 3px solid #f8ac59 !important;
}
tr.stripe_color_5,
div.stripe_color_5 {
	border-left: 3px solid #ed5565 !important;
}

td.edit_hover:hover {
	border: 2px dashed #bbbbbb !important;
}
tr.active, .dropdown-menu > a.active {
	background-color: lightSteelBlue !important;
}

tr.edited-in {
	transition: background-color 0.25s linear;
	background-color: khaki !important;
}
tr.edited-out {
	transition: background-color 0.25s linear;
	background-color: inherit !important;
}

/* TABLE 2ND LINE ------------------------------------------*/

tr.second-line-row > td > table > thead > tr > th,
tr.second-line-row > td > table > tbody > tr > td {
	padding: 0 8px !important;
	border: none !important;
}
tr.second-line-row > td > table > thead > tr,
tr.second-line-row > td > table > tbody > tr {
	background-color: transparent !important;
	border: none;
}
.second_line > td > div {
	text-overflow: ellipsis;
}


/* FORMS ---------------------------------------------------*/

.form-group {
	margin-bottom: 0.5rem; /* less space vertically between form fields */
}
.form-control {
	font-size: 0.8rem;
}
.label {
	border-radius: 1em;
}
label {
	vertical-align: top;
}
/* select.form-control {
    height: 2.20rem !important;
} */
form label {
	text-transform: capitalize;
}
.shaded-headers h1,
.shaded-headers h2,
.shaded-headers h3,
.shaded-headers h4,
.shaded-headers h5 {
	padding: 5px 15px;
	background-color: #f3f3f4;
}
.shaded-headers p {
	margin-left: 5px;
}
.react-datepicker-wrapper {
	flex: 1;
}
.badge.badge-default {
	background-color: #eeeeee;
}

/* MODAL ------------------------------------------------*/

.modal-sm {
	max-width: 400px;
}
.modal-xs {
	max-width: 300px;
}
.swal2-container {
	z-index: 2050 !important;
}

/* CHIPS ------------------------------------------------*/

.chip {
	color: #222222;
	font-size: 14px;
	font-weight: 400;
	padding: 5px 10px;
	line-height: 2.4;
}
.chip i {
	color: #888888;
	margin-left: 8px;
	font-size: 16px;
	margin-right: -4px;
}

/* DROPZONE ------------------------------------------------*/

.em-dropzone > div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	width: 100%;

	background-color: #f3f3f4;
	outline: 3px dashed #dddddd;
	outline-offset: -10px;
	border-radius: 20px;
}
.em-dropzone > div.highlight {
	background-color: #eeeeee;
	outline: none;
}
.em-dropzone.has-error {
	border: 1px solid red;
}
.em-dropzone-image {
	position: relative; 
	text-align: center; 
	border: 1px solid #e5e6e7; 
	padding: 10px;
	border-radius: 20px;
}
.em-dropzone-image > div {
	border-radius: 10px;
}

/* FIX FOR DATEPICKER INPUT GROUP --------------------------*/

.react-datepicker {
	font-weight: normal;
}
.input-group
	> .input-group-append:last-child
	> .btn:not(:last-child):not(.dropdown-toggle),
.input-group
	> .input-group-append:last-child
	> .input-group-addon:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-addon,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-addon {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.input-group-addon {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
.react-datepicker-popper {
	width: 350px;
}

/* TIMELINE -------------------------------------------------*/

.table.timeline {
	border-bottom: 1px solid #dee2e6;
}
.timeline_cell_wrapper {
	border-top: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
	width: 100%;
	height: 45px;
}
.timeline_cell_wrapper.index1,
.timeline_cell_wrapper.index2,
.timeline_cell_wrapper.index3,
.timeline_cell_wrapper.index4,
.timeline_cell_wrapper.index5,
.timeline_cell_wrapper.index6,
.timeline_cell_wrapper.index7 {
	border-top: none;
}
td:last-child > .timeline_cell_wrapper {
	border-right: 1px solid #dee2e6;
}
.table.timeline td {
	border: none;
	height: 44px;
	padding: 0;
}

.timeline_event {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #eeeeee;
	border: 1px solid #ffffff;
	padding: 5px 15px;
	border-radius: 2px;
	height: 46px;
	z-index: 1;
	white-space: nowrap;
	/* overflow: hidden; */
}
.timeline_event .drag_left {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 10px;
	/* cursor: move; */
	border: 2px dotted #dddddd;
}
.timeline_event .resize_right {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 5px;
	/* cursor: ew-resize; */
	background-color: #aaaaaa;
	border: 2px dotted #dddddd;
}
.timeline_event .arrow_right {
	width: 0;
	height: 0;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
	border-left: 22px solid #eeeeee;
	position: absolute;
	z-index: 9999;
	top: 0px;
	right: -22px;
}
.timeline_event .arrow_left {
	width: 0;
	height: 0;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
	border-right: 22px solid #eeeeee;
	position: absolute;
	z-index: 9999;
	top: 0px;
	left: -22px;
}
#tech_table {
	user-select: none !important;
}
.currentTimeline {
	width: 2px;
	height: 100%;
	background-color: white;
	border: 1px dashed green;
}

/*=========================
	MAPBOX STYLE
===========================*/
/* .mapbox-container {
	display: flex;
	justify-content: center;
	width: 100%;
}
.mapbox {
	min-width: 400px;
	min-height: 150px;
	width: auto;
}
.mapbox img{
	width: 100%;
	height: auto;
} */

/*=========================
	MODAL EXPAND / SHRINK STYLE
===========================*/

div.expand-icon-position {
	top: 0;
	right: 0;
	margin-top: 11px;
	margin-right: 50px;
	position: absolute;
}
div.expand-icon-style {
	font-size: 24px;
	color: rgb(187, 187, 187);
	cursor: pointer;
}
div.expand-icon-style:hover {
	font-size: 24px;
	color: black;
}

div.close-icon-position {
	top: 0;
	right: 0;
	margin-top: 5px;
	margin-right: 15px;
	position: absolute;
}
div.close-icon-style {
	font-size: 34px;
	color: rgb(187, 187, 187);
	cursor: pointer;
}
div.close-icon-style:hover {
	font-size: 34px;
	color: black;
}

div.modal-content {
	transition: all 0.5s ease;
}

div.expand-modal > div.modal-dialog > div.modal-content {
	width: 65vw;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -0%);
}

div.shrink-modal > div.modal-dialog > div.modal-content {
	width: 100%;
	left: 0;
}

/*=========================
	INVOICES
===========================*/

.title-bar {
	background: #aaaaaa;
	color: #fff;
	padding: 10px;
	font-weight: 700;
	/* max-width: 290px; */
}

.title {
	font-size: 2rem;
	font-weight: 700;
}

.r-titles {
	font-size: 1rem;
	font-weight: 700;
}
.r-titles-sub {
	font-size: 1rem;
}

.spacer {
	height: 45px;
}

table.items {
	border: 1px solid #888888;
}
table.items td {
	_text-align: center;
	border: 1px solid #888888;
	padding: 10px;
}
table.items thead th {
	padding: 10px;
}
table.items tr:nth-child(even) {
	background-color: #dddddd;
}

table.subtotal {
	border: none;
}
table.subtotal td {
	margin: 5px;
	padding: 5px;
}

/*=========================
	INVOICES
===========================*/

#signature-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: 0;
	padding: 32px 16px;
	background-color: #dddddd;
	font-family: Helvetica, Sans-Serif;
}

.signature-pad {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	font-size: 10px;
	width: 100%;
	height: 100%;
	max-width: 700px;
	max-height: 460px;
	border: 1px solid #e8e8e8;
	background-color: #fff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27),
		0 0 40px rgba(0, 0, 0, 0.08) inset;
	border-radius: 4px;
	padding: 16px;
}

.signature-pad::before,
.signature-pad::after {
	position: absolute;
	z-index: -1;
	content: "";
	width: 40%;
	height: 10px;
	bottom: 10px;
	background: transparent;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.signature-pad::before {
	left: 20px;
	-webkit-transform: skew(-3deg) rotate(-3deg);
	transform: skew(-3deg) rotate(-3deg);
}

.signature-pad::after {
	right: 20px;
	-webkit-transform: skew(3deg) rotate(3deg);
	transform: skew(3deg) rotate(3deg);
}

.signature-pad--body {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border: 1px solid #f4f4f4;
}

.signature-pad--body canvas {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.signature-pad--footer {
	color: #c3c3c3;
	text-align: center;
	font-size: 1.2em;
	margin-top: 8px;
}

.signature-pad--actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 8px;
}

/*=========================
	CHAT
===========================*/
.avatars {
	position: absolute;
	display: inline-flex;
	flex-direction: row-reverse;
	text-align: left;
}
.offSet-4 {
	left: 50px;
}
.offSet-2 {
	left: 20px;
}
.offSet-3 {
	left: 35px;
}

.avatar {
	position: relative;
	border: 3px solid #fff;
	border-radius: 50%;
	overflow: hidden;
	width: 50px;
	height: 50px;
}

.avatar:not(:last-child) {
	margin-left: -65px;
}

.avatar img {
	width: 100%;
	display: block;
}
.add-user-list {
	z-index: 2000;
	background-color: white;
	bottom: 125px;
	right: 40px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
	max-height: 390px;
	overflow-y: scroll;
}
.chat-container {
	position: fixed;
	bottom: 53px;
	right: 12px;
	z-index: 1100;
	width: 850px;
}

.open-button {
	background-color: #555;
	color: white;
	padding: 16px 20px;
	border: none;
	cursor: pointer;
	opacity: 0.8;
	width: 280px;
}
.chat-app.card {
	background: #fff;
	transition: 0.5s;
	border: 0;
	border-radius: 0.55rem;
	position: relative;
	width: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.chat-app .people-list {
	width: 280px;
	position: absolute;
	left: 0;
	top: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	z-index: 7;
}

.chat-app .chat {
	margin-left: 280px;
	border-left: 1px solid #eaeaea;
}

.people-list {
	-moz-transition: 0.5s;
	-o-transition: 0.5s;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.people-list .chat-list li {
	padding: 10px 15px;
	list-style: none;
	/* border-radius: 3px */
}

.people-list .chat-list li:hover {
	background: #efefef;
	cursor: pointer;
}
.user-select:hover {
	background: #efefef;
	cursor: pointer;
}

.people-list .chat-list li.active {
	background: #efefef;
}

.people-list .chat-list li .name {
	font-size: 15px;
}

.people-list .chat-list img {
	width: 50px;
	height: 50px;
	border-radius: 50%/50%;
	object-fit: cover;
}

.people-list img {
	float: left;
	height: auto;
	border-radius: 1000px;
}

.people-list .about {
	float: left;
	padding-left: 8px;
}

.people-list .status {
	color: #999;
	font-size: 13px;
}
.chat-header {
	height: 82px;
}
.chat .chat-header {
	padding: 15px 20px;
	border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
	float: left;
	border-radius: 40px;
	width: 40px;
}
.groupOffSet-4 {
	position: relative;
	left: 93px;
}
.groupOffSet-3 {
	position: relative;
	left: 78px;
}
.groupOffSet-2 {
	position: relative;
	left: 63px;
}

.chat .chat-header .chat-about {
	float: left;
	padding-left: 10px;
}
.chat-history {
	height: 500px;
	overflow-y: scroll;
}
.chat .chat-history {
	padding: 20px;
	border-bottom: 2px solid #fff;
}

.chat .chat-history ul {
	padding: 0;
}

.chat .chat-history ul li {
	list-style: none;
	margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
	margin-bottom: 0px;
}

.chat .chat-history .message-data {
	margin-bottom: 15px;
}

.chat .chat-history .message-data img {
	border-radius: 40px;
	width: 40px;
}

.chat .chat-history .message-data-time {
	color: #434651;
	padding-left: 6px;
}

.chat .chat-history .message {
	color: #444;
	padding: 18px 20px;
	line-height: 26px;
	font-size: 16px;
	border-radius: 7px;
	display: inline-block;
	position: relative;
}

.chat .chat-history .message:after {
	bottom: 100%;
	left: 7%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #fff;
	border-width: 10px;
	margin-left: -10px;
}

.chat .chat-history .my-message {
	background: #efefef;
}

.chat .chat-history .my-message:after {
	bottom: 100%;
	left: 30px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #efefef;
	border-width: 10px;
	margin-left: -10px;
}

.chat .chat-history .other-message {
	background: #e8f1f3;
	text-align: right;
}

.chat .chat-history .other-message:after {
	border-bottom-color: #e8f1f3;
	left: 93%;
}

.chat .chat-message {
	padding: 20px;
}

.online,
.offline,
.me {
	margin-right: 2px;
	font-size: 8px;
	vertical-align: middle;
}

.online {
	color: #86c541;
}

.offline {
	color: #e47297;
}

.me {
	color: #1d8ecd;
}

.float-right {
	float: right;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

@media only screen and (max-width: 767px) {
	.chat-app .people-list {
		height: 465px;
		width: 100%;
		overflow-x: auto;
		background: #fff;
		left: -400px;
		display: none;
	}
	.chat-app .people-list.open {
		left: 0;
	}
	.chat-app .chat {
		margin: 0;
	}
	.chat-app .chat .chat-header {
		border-radius: 0.55rem 0.55rem 0 0;
	}
	.chat-app .chat-history {
		height: 300px;
		overflow-x: auto;
	}
}

@media only screen and (min-width: 768px) and (max-width: 980) {
	.chat-app .chat-list {
		height: 650px;
		overflow-x: auto;
	}
	.chat-app .chat-history {
		height: 600px;
		overflow-x: auto;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
	.chat-app .chat-list {
		height: 480px;
		overflow-x: auto;
	}
	.chat-app .chat-history {
		height: calc(100vh - 350px);
		overflow-x: auto;
	}
}

/*=========================
	LOCATION-AUTOCOMPLETE
===========================*/

.pac-container {
	z-index: 5000 !important;
}

/*=========================
	Custom SideMenu
===========================*/

.custom-menu {
	font-size: 14px;
	width: 150px;
}
.custom-menu a {
	color: green !important;
}
.custom-menu > hr {
	margin-bottom: 20px;
}
.custom-menuitem {
	padding: 10px;
	border-radius: 5px;
}
.custom-menuitem-disabled {
	padding: 10px;
	color: #aaaaaa;
	border-radius: 5px;
	cursor: default;
}
.custom-menuitem-active {
	padding: 10px;
	color: white;
	background-color: #a68d42;
	border-radius: 5px;
}
.custom-menuitem {
	padding: 10px;
}
.custom-menuitem:hover {
	background-color: #bfa34d;
	color: white;
}
.custom-menu p {
	font-size: 10px;
	padding: 5px;
	color: #aaaaaa;
	margin-top: 15px;
	border-bottom: 1px solid #dddddd;
}

/*=========================
	Table Overrides
===========================*/
.group-row {
	color: white;
	background: #aaaaaa !important;
	/* border-left: 5px solid #aaaaaa; */
}
